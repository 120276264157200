// src/components/Footer.tsx
import React from 'react';
import { Box, Container, Typography, IconButton, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter'; // Assuming you meant to use Twitter instead of XIcon
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
    return (
        <Box component="footer" sx={{  bgcolor: '#333344', color: 'common.white' , py: 2, minHeight: '10px' }}>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="body1">© {new Date().getFullYear()} Vaartaakaar Films</Typography>
                    </Grid>
                    {/* <Grid item>
                        <IconButton aria-label="facebook" color="inherit" href="https://facebook.com">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton aria-label="twitter" color="inherit" href="https://twitter.com">
                            <TwitterIcon />
                        </IconButton>
                        <IconButton aria-label="instagram" color="inherit" href="https://instagram.com">
                            <InstagramIcon />
                        </IconButton>
                    </Grid> */}
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
