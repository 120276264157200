// src/HomePage.tsx
import  { useEffect, useState } from 'react';
import { fetchHomeContent } from './contentClient';
import { Grid, Typography} from "@mui/material";
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IHomeContentFields } from './types';

const Home = () => {
    const [homeContent, setHomeContent] = useState<IHomeContentFields | null>(null);
    // const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const loadContent = async () => {
            try {
                const content = await fetchHomeContent();
                setHomeContent(content);
            } catch (error) {
                console.log('Failed to load content');
            }
        };

        loadContent();
    }, []);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         if (homeContent && homeContent.img.length > 1) {
    //             setCurrentImageIndex((prevIndex) => (prevIndex + 1) % homeContent.img.length);
    //         }
    //     }, 5000); // Change image every 3 seconds

    //     return () => clearInterval(timer);
    // }, [homeContent]);

    // const handlePrevClick = () => {
    //     setCurrentImageIndex(prevIndex => (prevIndex - 1 + (homeContent?.img.length || 1)) % (homeContent?.img.length || 1));
    // };

    // const handleNextClick = () => {
    //     setCurrentImageIndex(prevIndex => (prevIndex + 1) % (homeContent?.img.length || 1));
    // };

    return (
        <Grid container spacing={2} sx={{paddingTop:'5px'}} >
            {homeContent ? (
                <Grid item xs={12}>
                 
                    <Typography variant='h5'>Title</Typography>
                    <Typography variant="body1" sx={{
                        textAlign: 'justify',
                        marginTop: 2,
                        borderBottom: 1,
                        borderColor: 'grey.500',
                        paddingBottom: 2,
                        marginX: 2  // Add horizontal margin if needed
                    }}>
                        {homeContent.home}
                    </Typography>
                </Grid>
            ) : (
                <Typography>Loading content...</Typography>
            )}
        </Grid>
    );
};

export default Home;
