// src/components/IntroComponent.tsx
import React, { useEffect, useState } from 'react';
import { fetchIntroContent } from './contentClient';
import { Typography, Box } from '@mui/material';
import { IntroContents } from './types';


const IntroComponent: React.FC = () => {
  const [introContent, setIntroContent] = useState<IntroContents>([]);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const content = await fetchIntroContent();
        setIntroContent(content);
      } catch (error) {
        console.error('Failed to load content:', error);
      }
    };

    loadContent();
  }, []);

  return (
    <div style={{borderBottom: '0.5px solid grey.500'}}>
      {introContent.length > 0 ? (
        introContent.map((content, index) => (
          <Box key={index}>
            <Typography variant="h5">{content.title}</Typography>
            <Typography variant="body1" sx={{
                        textAlign: 'justify',
                        marginTop: 2,
                     
                        paddingBottom: 2,
                        marginX: 2  // Add horizontal margin if needed
                    }}>{content.intro}</Typography>
          </Box>
        ))
      ) : (
        <Typography>No content available.</Typography>
      )}
    </div>
  );
};

export default IntroComponent;
