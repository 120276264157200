// src/components/NavigationBar.tsx
import React, { useEffect, useState } from 'react';
import { fetchNavigationData } from './contentClient';
import { AppBar, Toolbar, Button, Typography, useTheme, useMediaQuery, Box } from '@mui/material';
import { INavData } from './types';

const NavigationBar: React.FC = () => {
  const [navItems, setNavItems] = useState<INavData[]>([]);

  useEffect(() => {
    const loadNavData = async () => {
      const data = await fetchNavigationData();
      setNavItems(data);
    };

    loadNavData();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Helper function to format the navbar text
  const formatText = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const handleNavigation = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: '#333344', color: 'common.white' }}>

      <Toolbar sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Typography variant="h6" sx={{ flexGrow: 1, mr: 2, whiteSpace: 'nowrap' }} >
        PARAMARSH​
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {navItems.map((item) => (
            <Button
              key={item.id}
              color="inherit"
              onClick={() => handleNavigation(item.navbarText.toLowerCase())}
              sx={{ my: 1, mx: 0.5 }}
            >
              <Typography fontSize={isMobile ? 14 : 15}>
                {formatText(item.navbarText)}
              </Typography>
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
