
import './App.css';



import {  Route, Routes } from 'react-router-dom';
import HomePage from './Component/Master';

console.log('SPACE:', process.env.REACT_APP_SPACE);
console.log('Access Token:', process.env.REACT_APP_ACCESS_TOKEN);


function App() {
  return (
    <div className="App">
      <Routes>
      
      <Route path="/" element={<HomePage/>} />
     
   
  </Routes>
    </div>
  );
}

export default App;
