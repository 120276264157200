
import React, { useState, useEffect } from 'react';
import { fetchContentWithPDF } from './contentClient';
import { Typography, Box, Modal, Button, useMediaQuery, useTheme } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface ContentWithPDF {
    content: string;
    title:string;
    media: any[]; // Ensure this matches your actual data structure
}

const ContentWithPDFComponent: React.FC = () => {
    const [contents, setContents] = useState<ContentWithPDF[]>([]);
    const [open, setOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState<string | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const loadContent = async () => {
            const fetchedContent = await fetchContentWithPDF();
            setContents(fetchedContent);
        };

        loadContent();
    }, []);

    const handleOpen = (url: string) => {
        setPdfFile(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 0,
        p: 0,
        maxHeight: isMobile ? '80%' : '90%',
        overflow: 'auto'
    };

    return (
        <div style={{borderBottom: '0.5px solid black'}}>
            {contents.map((content, index) => (
                <Box key={index} mb={4}>
                    <Typography variant="h5">{content.title}</Typography>
                    <Typography variant="body1" sx={{
                        textAlign: 'justify',
                        marginTop: 2,
                        
                        paddingBottom: 2,
                        marginX: 2  // Add horizontal margin if needed
                    }}>{content.content}</Typography>
                    {content.media.map((mediaItem, idx) => (
                        <Box key={idx} mt={2}>
                            <Button onClick={() => handleOpen(mediaItem.fields.file.url)}>
                                View PDF Document {idx + 1}
                            </Button>
                        </Box>
                    ))}
                </Box>
            ))}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="pdf-viewer-modal"
                aria-describedby="pdf-viewer-modal-description"
            >
                <Box sx={modalStyle}>
                    <Button onClick={handleClose}>Close</Button>
                    {pdfFile && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer fileUrl={pdfFile}  />
                        </Worker>
                    )}
                   </Box>
            </Modal>
        </div>
    );
};

export default ContentWithPDFComponent;
