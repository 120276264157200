// src/components/VideoGallery.tsx
import React, { useState, useEffect } from 'react';
import { fetchVideos } from './contentClient';
import { Typography, Box, Grid } from '@mui/material';

interface VideoEntry {
  title: string;
  description?: string;
  videoUrl?: string;
  videoFileUrl?: string;
}

const VideoGallery: React.FC = () => {
  const [videos, setVideos] = useState<VideoEntry[]>([]);

  useEffect(() => {
    const loadVideos = async () => {
      const fetchedVideos = await fetchVideos();
      setVideos(fetchedVideos);
    };

    loadVideos();
  }, []);

  
  
  return (
    <Grid container spacing={2}>
      {videos.map((video, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box>
            <Typography variant="h6">{video.title}</Typography>
            <Typography variant="body2">{video.description}</Typography>
            <video controls width="100%" height='350px'>
  <source src={video.videoUrl || video.videoFileUrl || ''} type="video/mp4" />
  Your browser does not support the video tag.
</video>

          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default VideoGallery;
