import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';

interface PowerPointViewerProps {
  src: string;
}

const PowerPointViewer: React.FC<PowerPointViewerProps> = ({ src }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const iframeStyle = {
    width: isMobile ? '100%' : '1680px',
    height: isMobile ? '248px' : '700px',
    border: '0'
  };

  return (
    <div>
    <Box display="flex" justifyContent="center" alignItems="center">
      <iframe
        src={src}
        style={iframeStyle}
        allowFullScreen
      >
        This is an embedded <a href="https://office.com" target="_blank" rel="noopener noreferrer">Microsoft Office</a> presentation, powered by <a href="https://office.com/webapps" target="_blank" rel="noopener noreferrer">Office</a>.
      </iframe>
    </Box>
    </div>
  );
};

export default PowerPointViewer;
