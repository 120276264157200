// src/services/contentfulService.ts

// import { createClient } from 'contentful';

// const client = createClient({
//   space: 'b1q2va7nhti2' as string,
//   accessToken: 'c9J8CJ1EJ7O6HZzDOE7aTPcOA3iehOgVAfuUhkd_MhE' as string,
// });

// export const fetchEntries = async () => {
//   const entries = await client.getEntries();
//   if (entries.items) return entries.items;
//   console.log('Error getting Entries');
// };

// export const fetchEntryById = async (entryId: string) => {
//   const entry = await client.getEntry(entryId);
//   return entry;
// };
// src/types/contentfulTypes.ts
// src/services/contentfulService.ts

// import { createClient } from 'contentful';
// const client = createClient({
//   space: 'b1q2va7nhti2' as string,
//    accessToken: 'c9J8CJ1EJ7O6HZzDOE7aTPcOA3iehOgVAfuUhkd_MhE' as string,
// });

// export const fetchNavigation = async () => {
//   const entries = await client.getEntries({
//     content_type: 'navigation'  // Adjust this to your specific Contentful content type ID
//   });
//  const response = entries.items;
//  console.log(response);
// };

// export default client;
import { Asset, createClient, EntryCollection } from 'contentful';
import { INavData } from './types';
//import { IContentfulResponse, INavData } from '../types/contentfulTypes';
interface VideoAsset {
  sys: {
    id: string;
  };
  fields: {
    file: {
      url: string;
    };
  };
}

interface VideoEntryFields {
  title: string;
  description?: string;
  videoUrl: string;
  videoFileUrl?: string;
}

const SPACE = process.env.REACT_APP_SPACE;
const accessToken = process.env.REACT_APP_ACCESS_TOKEN;



//const API = process.env.REACT_APP_ACCESS_TOKEN;
const client = createClient({ 
  space: SPACE || 'b1q2va7nhti2',
  accessToken: accessToken || 'c9J8CJ1EJ7O6HZzDOE7aTPcOA3iehOgVAfuUhkd_MhE',
});




export const fetchNavigationData = async (): Promise<INavData[]> => {
  try {
    const entries: EntryCollection<any> = await client.getEntries<any>({
      content_type: 'navigation'

   
    }); 

    if (entries.items.length > 0) {
      return entries.items.map((item) => ({
        id: item.sys.id,
        navbarText: typeof item.fields.navbar === 'string' ? item.fields.navbar : "Default text"  // Handling non-string and undefined values
      }));
    } else {
      console.log('No items found in Contentful entries.');
      return [];
    }
  } catch (error) {
    console.error('Error getting Navigation Entries:', error);
    return [];
  }
};

export const fetchHomeContent = async (): Promise<any> => {
  try {
      const entries: EntryCollection<any> = await client.getEntries({
          content_type: 'home'
      });
      if (entries.items.length > 0) {
          const { fields } = entries.items[0];
          if (!fields.img || !Array.isArray(fields.img)) {
              fields.img = []; // Default to empty array if not present or not an array
          } else {
              fields.img = (fields.img as any[]).map((image: any) => ({ // Safe type assertion and map
                  ...image.fields,
                  file: {
                      ...image.fields.file,
                      url: image.fields.file.url.startsWith('//') ? 'https:' + image.fields.file.url : image.fields.file.url
                  }
              }));
          }
          return fields;
      }
      throw new Error("No content found.");
  } catch (error) {
      console.error('Error fetching home content:', error);
      throw error;
  }
};
export const fetchIntroContent = async (): Promise<any> => {
  try {
    const response = await client.getEntries({
      content_type: 'intro'
    });

    if (response.items.length > 0) {
      return response.items.map(item => ({
        intro: item.fields.intro,
        title: item.fields.title
      }));
    }
    return []; // Return an empty array if no items found
  } catch (error) {
    console.error('Error fetching intro content:', error);
    throw error; // Rethrow after logging
  }
};

export const fetchContentWithPDF = async (): Promise<any[]> => {
  try {
    const entries = await client.getEntries<any>({
      content_type: 'contentWithPdf'
    });

    if (entries.items.length > 0) {
      return entries.items.map(item => ({
        content: item.fields.content,
        media: item.fields.media,
        title: item.fields.title // Assuming the PDFs are stored in an array of assets
      }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};





export const fetchVideos = async (): Promise<any[]> => {
  const response = await client.getEntries<any>({
    content_type: 'video'
  });

  return response.items.map(item => {
    const title: string = typeof item.fields.title === 'string' ? item.fields.title : 'No title';
    const description: string = typeof item.fields.description === 'string' ? item.fields.description : '';
    const videoUrl: string | undefined = typeof item.fields.videoUrl === 'string' ? item.fields.videoUrl : undefined;
    let videoFileUrl: string | undefined = undefined;

    if (item.fields.videos && Array.isArray(item.fields.videos) && item.fields.videos.length > 0) {
      const firstVideo = item.fields.videos[0] as Asset; // Cast to Asset if you are confident in the structure, otherwise add more checks
      if (firstVideo && firstVideo.fields && firstVideo.fields.file && typeof firstVideo.fields.file.url === 'string') {
        videoFileUrl = `https:${firstVideo.fields.file.url}`;
      }
    }

    return {
      title,
      description,
      videoUrl,
      videoFileUrl
    };
  });
};