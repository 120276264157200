

import { CssBaseline, Grid, Typography } from "@mui/material";
import NavigationBar from "./Navbar";
import React from "react";
import Home from "./Home";
import IntroComponent from "./story";
import ContentWithPDFComponent from "./ContentWithPDF";
import VideoGallery from "./Videos";
import Footer from "./Footer";
import PowerPointViewer from "./PowerPoint";

const HomePage = () => (
    <React.Fragment>
     
     
      {/* Main content goes here */}
    
  <Grid container spacing={2}  style={{ paddingTop: '45px' , background: 'linear-gradient(to bottom, #33334444, #33334444)'}} >
    <Grid item xs={12}>
    <NavigationBar />
    </Grid>
    <Grid item xs={12} id='home'>
    <Home/>
    </Grid>
    <Grid item xs={12} id= 'vision'>
    <IntroComponent/>
   </Grid>
   <Grid item xs={12} id='pitch deck'>
    <PowerPointViewer src="https://1drv.ms/p/c/31d086783b687f37/IQOoIvDJms01SLCduM9LkxajAYVrcDlB5_X0xAG7c_zzLtQ?em=2&amp;wdAr=1.7777777777777777"/>
    </Grid>  
    <Grid item xs={12} id='vaartaa'>
    <ContentWithPDFComponent/>
    </Grid>
    <Grid item xs={12} id='videos'>
      <VideoGallery/>
    </Grid>
       
    <Grid item xs={12}>
    <Footer/>
    </Grid>
  </Grid>
  
  </React.Fragment>
);

export default HomePage;
